<template>
  <div>
    <div class="box">
      <div
        style="display: flex;align-items: center;max-width: 60%"
        @click="routerBack"
        :class="{ isActive: !userId }"
      >
        <van-icon name="arrow-left" size="20px" style="margin-top: 3px" />
        <div
          style="margin-left: 5px;max-width: 90%;white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;"
        >
          {{ title }}
        </div>
      </div>
      <div
        style="display: flex;justify-content: space-between;"
        :style="{ width: collectionStatus ? '135px' : '120px' }"
      >
        <div @click="collection">
          <template v-if="collectionStatus">
            <van-icon name="star" color="orange" size="20px" />
            已收藏
          </template>
          <template v-else>
            <van-icon
              name="star-o"
              size="20px"
              :class="{ isActive: !userId }"
            />
            <span :class="{ isActive: !userId }">收藏</span>
          </template>
        </div>
        <div style="display: flex;align-items: center;" @click="errorOpen">
          <img
            :src="errorCorrection"
            style="width: 16px;height: 16px;margin-right: 3px;"
            :class="{ isActive: !userId }"
          />
          <span :class="{ isActive: !userId }">纠错</span>
        </div>
      </div>
      <van-popup
        v-model="errorVan"
        position="bottom"
        :style="{ height: '60%', width: openWidth, left: openLeft }"
      >
        <div class="errorLog" @click="errorLog_btn">
          查看我的纠错记录>>
        </div>
        <van-field
          ref="errorText"
          v-model="errorText"
          rows="1"
          autosize
          type="textarea"
          style="height: 130px;width: 95%;margin: 15px auto 0 auto;border: 0"
          placeholder="请输入"
          @click="errorFocs"
        />
        <van-button
          @click="submit"
          type="info"
          block
          style="width: 95%;margin: 15px auto 0 auto;border: 0"
          >提交</van-button
        >
      </van-popup>
    </div>
    <div class="login">
      <login v-model="loginShow" :title="loginTitle"> </login>
    </div>
  </div>
</template>

<script>
import errorCorrection from "@/assets/errorCorrection.png";
import login from "@/components/login.vue";
import {
  delFormulaCollectionApi,
  getFormulaCollection,
  postFormulaCollectionApi,
  postFormulaCollectionJCApi,
} from "@/service/api";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
export default {
  name: "FormulaBanner",
  components: { login },
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    formulaCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorCorrection,
      collectionStatus: false,
      errorVan: false,
      errorText: "",
      openWidth: "100%",
      openLeft: 0,
      userId: "",
      loginShow: false,

      beforeUrl: null,
      loginTitle: "登录后进行操作",
    }; //dataReturn
  },
  async created() {
    if (this.$route.query.memberId) {
      this.$store.commit("memberIdDocument", this.$route.query.memberId);
    }
    await this.test();
    await this.getYesNo();
    this.bottomShow();
    const old1 = document.getElementsByClassName("myPatient");
    console.log(old1);
  },

  watch: {
    $route(now) {
      console.log(now);
      this.test();
      this.getYesNo();
      this.bottomShow();
    },
  },
  methods: {
    routerBack() {
      // console.log(this.beforeUrl);
      this.$router.go(-1);
    },
    async test() {
      //像接口头部传用户信息
      this.userId = this.$store.state.memberIdDocument;
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getYesNo() {
      const data = {
        contentId: this.$router.currentRoute.query.id,
      };
      const res = await getFormulaCollection(data);
      this.collectionStatus = res.code === 0;
    },
    async collection() {
      if (this.$store.state.memberIdDocument == "") {
        this.loginShow = true;
        this.loginTitle = "登录后进行操作";
        return;
      }
      const status = !this.collectionStatus;
      if (status) {
        const data = {
          contentId: this.$route.query.id,
          contentType: "YXGS",
          channel: this.$route.query.channel,
        };
        const res = await postFormulaCollectionApi(data);
        if (res.code === 0) {
          Toast.success("收藏成功");
          this.collectionStatus = !this.collectionStatus;
        } else {
          Toast.fail(res.message);
        }
      } else {
        const data = {
          contentId: this.$route.query.id,
          contentType: "YXGS",
          channel: this.$route.query.channel,
        };
        const res = await delFormulaCollectionApi(data);
        if (res.code === 0) {
          Toast.success("已取消收藏");
          this.collectionStatus = !this.collectionStatus;
        } else {
          Toast.fail(res.message);
        }
      }
    },
    errorOpen() {
      if (this.$store.state.memberIdDocument == "") {
        this.loginShow = true;
        this.loginTitle = "登录后进行操作";
        return;
      }
      const app = document.getElementById("app");
      this.openWidth = app.offsetWidth + "px";
      this.openLeft = app.offsetLeft + "px";
      this.errorVan = true;
    },
    errorFocs() {
      this.$refs.errorText.focus();
    },
    async submit() {
      if (this.errorText.trim().length === 0) {
        Toast.fail("内容不能为空");
        return;
      }
      const data = {
        content: this.errorText,
        formulaId: this.$route.query.id,
        formulaCode: this.formulaCode,
      };
      const res = await postFormulaCollectionJCApi(data);
      if (res.code === 0) {
        Toast.success("提交成功");
        this.errorText = "";
        this.errorVan = false;
      } else {
        Toast.fail(res.message);
      }
    },
    errorLog_btn() {
      this.$router.push({ path: "/medicalErrorLog" });
    },
    bottomShow() {
      if (this.$store.state.memberIdDocument == "") {
        // 是否已经存在节点
        if (document.getElementById("new")) {
          console.log(document.getElementById("new"));
        } else {
          let that = this;
          const old = document.getElementsByClassName("formBody");
          const newElement = document.createElement("p");
          const myPatient = document.getElementsByClassName("myPatient");
          // console.log(myPatient.length);

          if (myPatient.length == 1) {
            newElement.innerHTML =
              '<div style="color:#1989fa;test-algin:center;margin-left:85px" id="new">点击此处登录，记录结果</div>';
            old[0].appendChild(newElement);
            newElement.addEventListener("click", function() {
              that.loginShow = true;
            });
          } else {
            newElement.innerHTML =
              '<div style="width:100%;color:#1989fa;margin:0 auto; text-align: center;" id="new">点击此处登录</div>';
            old[0].appendChild(newElement);
            newElement.addEventListener("click", function() {
              that.loginShow = true;
            });
          }
        }
      } else {
        const newElement = document.getElementById("new");
        newElement.parentNode.removeChild(newElement);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  padding-left: 9px;
}
.van-popup {
  background-color: #f6f6f6;
  height: 350px;
  //  background-color: red;
}
.van-button::before {
  border-radius: 160px;
  overflow: hidden;
}
.errorLog {
  text-align: right;
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
  color: #0a84ff;
}
.isActive {
  color: rgb(98, 100, 102);
}

::v-deep .login .van-popup {
  width: 300px;
  height: 350px;
  border-radius: 20px;
  background: white;
}

::v-deep .login .van-field__control {
  height: 24px;
}
</style>
